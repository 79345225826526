import React from 'react'
import styled from 'styled-components'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUsePtag } from './ReUsePtag'
import { ReUseAnchorTag } from './ReUseAnchorTag'
import { LinkHubspot } from './LinkHubspot'
import { Button } from './Button'

const ContactsUsCardWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CardSize {
    min-width: 400px;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    height: 520px;
    box-shadow: 5px 5px 5px 5px #f6f8fa;
    padding-top: 2rem;
  }
  .UpperSection {
    background-color: #34a1d5;
    height: 200px;
  }
  .IconStyle {
    height: 100px;
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 2rem;
  }

  .CardHeading {
    color: #fff;
    text-align: center;
    padding-top: 2rem;
  }
  .CardPara {
    color: #047cc2;
    text-align: left;
    padding: 1rem 25px 2.5rem 25px;
  }
  .CardPhone {
    color: #047cc2;
    text-align: center;
    font-size: 1.5rem;
  }
  .CardPhone:hover {
    color: #004991;
  }
  .CardEmail {
    color: #047cc2;
    text-align: center;
    font-size: 1.5rem;
  }
  .telPhone {
    text-decoration: none;
  }
  .telPhone:hover {
    text-decoration: underline;
    text-decoration-color: #004991;
  }
`

export const ContactsUsCard = (props?: any) => {
  return (
    <ContactsUsCardWapper>
      <div className='CardSize'>
        <div className='UpperSection'>
          <ReuseImgTag ImageStyle='IconStyle' ImagePath={props.CardImage} AltName={props.CardAlt} />
          <ReUseHTwotag HeadingStyle='CardHeading' Heading={props.CardHeading} />
        </div>
        <div className='LowerSection'>
          <ReUsePtag paraStyle='CardPara' para={props.CardPara} />
          <ReUseAnchorTag href='tel:1-408-899-2224' anchorStyle='telPhone' target='' rel='noopener noreferrer'>
            <ReUsePtag paraStyle='CardPhone' para={props.CardPhone} />
          </ReUseAnchorTag>
          <ReUseAnchorTag href={props.mailTo} anchorStyle={props.iconimg} target='_blank' rel='noopener noreferrer'>
            <Button cta={props.CardEmail} className={props.Toggle}>
              {props.CardEmail}
            </Button>
          </ReUseAnchorTag>
          <LinkHubspot to={props.URL} className={props.ListType}>
            <Button cta={props.ButtonName} className={props.Toggle}>
              {props.ButtonName}
            </Button>
          </LinkHubspot>
        </div>
      </div>
    </ContactsUsCardWapper>
  )
}
