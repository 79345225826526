import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUsePtag } from './ReUsePtag'
import ContactBannerData from '../Data/ContactBannerData.json'

const AppContactUsBannerWapper = styled.div`
  /* background-color: #d9f2fc; */
  .mainSection {
    max-width: 1920px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 130px 96px 60px 96px;
    @media (max-width: 1080px) {
      padding: 100px 15px 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .headingSection {
    padding-left: 3rem;
    padding-top: 7rem;
    @media (max-width: 1080px) {
      padding-left: unset;
      padding-top: unset;
      padding-bottom: 3rem;
      margin: auto;
    }
  }
  .ContactBannerHeading {
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    @media (max-width: 1080px) {
      text-align: center;
    }
  }
  .ContactBannerpara {
    max-width: 500px;
    line-height: 2em;
    @media (max-width: 1080px) {
      text-align: center;
      max-width: unset;
    }
  }
  .imageSection {
    width: 30%;
    padding-bottom: 5rem;
    padding-right: 2rem;
    @media (max-width: 1080px) {
      width: 100%;
      padding-bottom: unset;
      padding-right: unset;
    }
  }
  .ContactBannerimage {
    width: 100%;
    height: auto;
  }
`

export const AppContactUsBanner = () => {
  return (
    <AppContactUsBannerWapper>
      {ContactBannerData.map((data, index) => {
        return (
          <div className='mainSection' key={index}>
            <div className='headingSection'>
              <ReUseHOneTag Heading={data.heading} HeadingStyle='ContactBannerHeading' />
              <ReUsePtag para={data.para} paraStyle='ContactBannerpara' />
            </div>
            <div className='imageSection'>
              <ReuseImgTag ImageStyle='ContactBannerimage' ImagePath={data.ImagePath} AltName={data.AltName} />
            </div>
          </div>
        )
      })}
    </AppContactUsBannerWapper>
  )
}
